@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Space+Mono:wght@400;700&display=swap);
body {
  margin: 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  min-height: 100vh;
  width: 100vw;
}
/* #root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

